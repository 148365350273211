type RiskLevel = 'high_risk' | 'medium_risk' | 'resolved_risk';

interface RiskDescriptions {
  [key: string]: {
    [key in RiskLevel]: string;
  };
}

export const riskDescriptions: RiskDescriptions = {
  Payload: {
    high_risk: 'operating with elevated payload.',
    medium_risk: 'operating with elevated payload',
    resolved_risk: 'operating with elevated payload.',
  },
  Speed: {
    high_risk: 'operating beyond the speed limit.',
    medium_risk: 'operating beyond the speed limit.',
    resolved_risk: 'operating beyond the speed limit.',
  },
  Location: {
    high_risk: 'frequently exceeding the proximity range.',
    medium_risk: 'frequently exceeding the proximity range.',
    resolved_risk: 'frequently exceeding the proximity range.',
  },
  Safety: {
    high_risk: 'experiencing critical issues with the safety sensor.',
    medium_risk: 'experiencing critical issues with the safety sensor.',
    resolved_risk: 'experiencing critical issues with the safety sensor.',
  },
  Camera: {
    high_risk: 'experiencing severe malfunctions with camera sensor.',
    medium_risk: 'experiencing severe malfunctions with camera sensor.',
    resolved_risk: 'experiencing severe malfunctions with camera sensor.',
  },
  Software: {
    high_risk: 'running outdated software versions.',
    medium_risk: 'running outdated software versions.',
    resolved_risk: 'running outdated software versions.',
  },
  Authorization: {
    high_risk: 'operating without an authorization mechanism.',
    medium_risk: 'operating without an authorization mechanism.',
    resolved_risk: 'operating without an authorization mechanism.',
  },
  Configuration: {
    high_risk: 'operating with an outdated configuration.',
    medium_risk: 'operating with an outdated configuration.',
    resolved_risk: 'operating with an outdated configuration.',
  },
  Calibration: {
    high_risk: 'operating without calibrated speed.',
    medium_risk: 'operating without calibrated speed.',
    resolved_risk: 'operating without calibrated speed.',
  },
};
